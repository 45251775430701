import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import useIcons from '../../assets/icons/useIcons';
import Spinner from '../Spinner';
import Button from '../Button';
import { useAlert } from '../../hooks/useAlert';
import AsideModal from '../AsideModal';
import Tabbing from '../Tabbing';
import GlobalFilter2 from '../GlobalFilter2';
import FormSelect from '../FormSelect';

const pageNumberListLimit = 5;
const TableManager = ({
  fetcherKey = '',
  // fetcherFn = () => {},
  fetcherFn = () => {
    return null;
  },
  columns = [],
  tableActions = [],
  role = '',
  name = '',
  shouldShowColumnName = true,
  extraParams = {},
  showPagination = true,
  showHeader = true,
  shouldFilter = false,
  initialValues,
  initialFilterState,
  withTabs = false,
  showPageCount = true,
  tab = 0,
  setTab = () => null,
  tabsLabels = [],
  isSearching = false,
  searchList = [],
  SearchComponentOption = <></>,
  searchKey = '',
  autoSelectFirstOption = false,
}) => {
  const {
    RefreshIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    FilterIcon,
  } = useIcons();
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const { showAlert } = useAlert();
  const memoizedColumns = useMemo(() => columns, [columns]);
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [maxPageNumberListLimit, setMaxPageNumberListLimit] = useState(5);
  const [minPageNumberListLimit, setMinPageNumberListLimit] = useState(0);
  const [searchPayload, setSearchPayload] = useState('');
  const memoizedSorting = useMemo(() => sorting, [sorting]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const search_filters = useMemo(() => {
    let response = [];
    if (filters.length > 0) {
      filters.forEach((filter) => {
        if (filter.key === 'date' && filter.value.from && filter.value.to) {
          response.push({
            key: filter.key,
            dateType: filter.dateType,
            value: filter.value,
          });
        } else if (filter.key !== 'date' && filter.value) {
          response.push({
            key: filter.key,
            value: filter.value,
          });
        }
      });
    }
    return response;
  }, [filters]);

  let payload = {
    page: pageIndex + 1,
    limit: pageSize,
    sort: memoizedSorting[0] || {},
    filter: search_filters,
    ...extraParams,
  };

  const queryPayload = isSearching ? { uuid: searchPayload } : {};

  const {
    isLoading,
    isFetching,
    refetch,
    data: tbldata,
  } = useQuery({
    queryFn: ({ queryKey }) => fetcherFn(queryKey[1] || {}),
    queryKey: [`${fetcherKey}`, { ...payload, ...queryPayload }],
    onError: (error) => {
      const errMsg = error?.message ? error?.message : 'unexpected error';
      showAlert({ type: 'secondary', message: errMsg });
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    enabled: isSearching ? Boolean(searchPayload) : true,
  });

  const filteredData =
    tbldata?.data?.data?.filter((item) => {
      if (role === 'AGENT') {
        return item?.role === 'AGENT';
      } else if (role === 'MEMBER') {
        return item?.role === 'MEMBER';
      } else if (role === 'AGENCY_USER') {
        return item?.role === 'AGENCY_USER';
      } else {
        return true; // Return all data if the role doesn't match any of the above
      }
    }) || tbldata?.data?.data;

  useEffect(() => {
    if (isSearching && autoSelectFirstOption) {
      setSearchPayload(searchList?.[0]?.[searchKey]);
    }
  }, [isSearching, searchList, autoSelectFirstOption]);

  const tableInstance = useReactTable({
    debugAll: false,
    // data: tbldata?.data?.data.filter((data)=>data?.role === role) || [],
    data: filteredData || [],
    // data:[],
    columns: memoizedColumns || [],
    columnResizeMode: 'onChange',
    state: {
      pagination,
      sorting: memoizedSorting,
      rowSelection,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: tbldata?.data?.lastPage ?? 1,
    // pageCount : 1,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
  });

  const handleNextPage = () => {
    tableInstance?.nextPage();
    if (pageIndex === tableInstance?.getPageCount() - 1) {
      return false;
    } else {
      if (pageIndex + 2 > maxPageNumberListLimit) {
        setMaxPageNumberListLimit(maxPageNumberListLimit + pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit + pageNumberListLimit);
      }
    }
  };
  const handlePreviousPage = () => {
    tableInstance?.previousPage();
    if (pageIndex === 0) {
      return;
    } else {
      if (pageIndex % pageNumberListLimit === 0) {
        setMaxPageNumberListLimit(maxPageNumberListLimit - pageNumberListLimit);
        setMinPageNumberListLimit(minPageNumberListLimit - pageNumberListLimit);
      }
    }
  };
  const handleLastPage = () => {
    tableInstance?.setPageIndex(tableInstance?.getPageCount() - 1);
    const min = tableInstance.getPageCount() - 5;
    const max = tableInstance.getPageCount();

    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };
  const handleFirstPage = () => {
    tableInstance?.setPageIndex(0);
    const min = 0;
    const max = 5;
    setMaxPageNumberListLimit(max);
    setMinPageNumberListLimit(min);
  };

  return (
    <>
      {showHeader && (
        <div className="w--full d--flex align-items--center justify-content--between  m-b--sm ">
          <div className="font--md font--400 d--flex align-items--center gap--sm w-min--200">
            {' '}
            {name ? (
              <span className="d--flex font--600">{name}</span>
            ) : (
              <span></span>
            )}
            {(isSearching
              ? Boolean(searchPayload) && (isLoading || isFetching)
              : isLoading || isFetching) && (
              <span className="d--flex ">
                <Spinner />
              </span>
            )}
          </div>
          {isSearching && (
            <div className="w--full w-max--300">
              <FormSelect
                extraClasses="border--0"
                options={searchList}
                value={searchPayload}
                renderOption={SearchComponentOption}
                onChange={(e) => setSearchPayload(e.target.value)}
                defaultOption={true}
              />
            </div>
          )}
          {withTabs ? (
            <div className="w--full d--flex align-items--center justify-content--center">
              <Tabbing tab={tab} setTab={setTab} tabsLabels={tabsLabels} />
            </div>
          ) : null}
          <div className="d--flex align-items--center justify-content--end gap--sm ">
            <span className="d--flex gap--sm">
              {tableActions &&
                tableActions.length > 0 &&
                tableActions.map(
                  ({ id, component: ElmComponent, access }, index) => {
                    if (!access)
                      return <React.Fragment key={index}></React.Fragment>;
                    return <span key={id}>{ElmComponent}</span>;
                  },
                )}
            </span>

            {shouldFilter && (
              <span className="position--relative">
                {search_filters.length > 0 ? (
                  <span className="bg--secondary radius--full p-l--sm  p-r--sm w-max--60 text--white d--flex justify-content--center align-items--center position--absolute right--1">
                    {search_filters.length}
                  </span>
                ) : null}
                <Button
                  variant="primary-200"
                  btnClasses="btn w-max--36 box-shadow--xs"
                  color="black"
                  type="button"
                  borderStyle="primary"
                  isOutline
                  icon={<FilterIcon width={20} height={20} />}
                  onClick={() => setOpenFilter(true)}
                />
              </span>
            )}
            <Button
              variant="primary-200"
              btnClasses="btn w-max--36 box-shadow--xs"
              color="black"
              type="button"
              borderStyle="primary"
              isOutline
              icon={<RefreshIcon width={20} height={20} />}
              onClick={() => refetch()}
            />
          </div>
        </div>
      )}

      <div className="w--full table--responsive bg--white table--responsive--full-scroll w--full  radius--sm bg--white box-shadow--xs p-t--0 p-b--xs  p-l--xs  p-r--xs">
        <table className="table border-bottom--black-100 ">
          {shouldShowColumnName && (
            <thead>
              {tableInstance?.getHeaderGroups()?.map((headerGroup) => (
                <tr key={headerGroup?.id}>
                  {headerGroup?.headers?.map((header) => {
                    return (
                      <th
                        key={header?.id}
                        style={{
                          textAlign:
                            header?.column?.columnDef?.meta?.textAlign ??
                            'left',

                          width:
                            header?.column?.columnDef?.meta?.width ||
                            `${(
                              100 / header?.headerGroup?.headers?.length
                            ).toFixed(2)}%`,
                        }}
                      >
                        {header?.isPlaceholder ? null : header?.column.columnDef
                            .meta?.isSortable ? (
                          <div
                            onClick={header?.column?.getToggleSortingHandler()}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            {{
                              asc: <RefreshIcon />,
                              desc: <RefreshIcon />,
                            }[header.column.getIsSorted()] ?? <RefreshIcon />}
                          </div>
                        ) : (
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
          )}

          <tbody>
            {tableInstance?.getRowModel()?.rows?.length > 0 ? (
              tableInstance?.getRowModel()?.rows?.map((row) => {
                return (
                  <tr
                    key={row?.id}
                    className={`${
                      rowSelection?.[row?.index] ? 'selected' : ''
                    }`}
                  >
                    {row?.getVisibleCells()?.map((cell) => {
                      return (
                        <td
                          key={cell?.id}
                          style={{
                            textAlign:
                              cell?.column?.columnDef?.meta?.textAlign ??
                              'left',
                            width:
                              cell?.column?.columnDef?.meta?.width ||
                              `${(
                                100 / cell?.headerGroup?.headers?.length
                              ).toFixed(2)}%`,
                            cursor: cell?.column?.columnDef?.meta?.shouldPointer
                              ? 'pointer'
                              : '',
                          }}
                        >
                          <div>
                            {cell?.column?.id === 'action' ||
                            cell?.column?.columnDef?.meta?.colType === 'action'
                              ? flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )
                              : cell?.getValue() ||
                                parseInt(cell?.getValue()) === 0 ||
                                cell?.getValue() === false
                              ? flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )
                              : '---'}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={tableInstance?.getAllColumns()?.length}
                  align="center"
                >
                  <span className="w--full d--flex justify-content--center">
                    No Record Found
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {(showPageCount || showPagination) && (
        <div className="w--full d--flex align-items--center justify-content--between  m-t--md">
          {showPageCount && (
            <div className=" d--flex gap--sm align-items--center w--full">
              <span>
                <span className="font--600">Page </span>
                <span>
                  {tableInstance?.getState()?.pagination?.pageIndex + 1}{' '}
                </span>
                of <span>{tableInstance?.getPageCount()}</span>
              </span>
              <span>|</span>
              <span className="d--flex gap--sm align-items--center w-max--300 w--full">
                <span className="font--600">Show</span>
                <select
                  required
                  className="form--control w--full h-min--28 h-max--28 w-max--60  radius--sm p-l--xs p-r--xs"
                  value={String(
                    tableInstance?.getState()?.pagination?.pageSize,
                  )}
                  onChange={(e) => {
                    tableInstance?.setPageSize(Number(e.target.value));
                  }}
                >
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                </select>
                <span className="w--full d--flex">records per page</span>
              </span>
            </div>
          )}

          {/* !isLoading &&  this will go below showPagination */}
          {showPagination && tableInstance?.getRowModel()?.rows?.length > 0 && (
            <div className="d--flex align-items--center justify-content--end">
              <div className="d--flex gap--xs">
                <Button
                  onClick={() => handleFirstPage()}
                  disabled={!tableInstance?.getCanPreviousPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--28 r-t-left--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center  ${
                    !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <DoubleArrowLeftIcon width={18} height={18} />
                </Button>
                <Button
                  onClick={() => handlePreviousPage()}
                  disabled={!tableInstance?.getCanPreviousPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanPreviousPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <ArrowLeftIcon width={18} height={18} />
                </Button>

                <div className="d--flex gap--xs align-item--center ">
                  {tableInstance?.getPageOptions()?.map((page, index) => {
                    const number = page + 1;
                    if (
                      number < maxPageNumberListLimit + 1 &&
                      number > minPageNumberListLimit
                    ) {
                      return (
                        <Button
                          key={page}
                          onClick={() => tableInstance?.setPageIndex(index)}
                          btnClasses={`  c--pointer h-min--28 h-max--28 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                            tableInstance?.getState()?.pagination?.pageIndex ===
                            index
                              ? ' bg--primary'
                              : 'bg--white '
                          } `}
                          type="button"
                          variant="white"
                          color={` ${
                            tableInstance?.getState()?.pagination?.pageIndex ===
                            index
                              ? ' text--black'
                              : 'text--black '
                          } `}
                        >
                          {page + 1}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>

                <Button
                  onClick={() => handleNextPage()}
                  disabled={!tableInstance?.getCanNextPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanNextPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <ArrowRightIcon width={18} height={18} />
                </Button>
                <Button
                  onClick={() => handleLastPage()}
                  disabled={!tableInstance?.getCanNextPage()}
                  btnClasses={` bg--white c--pointer h-min--28 h-max--32 radius--sm   w-max--28 w-min--28 d--flex align-items--center justify-content--center ${
                    !tableInstance?.getCanNextPage() ? 'disabled' : ''
                  } `}
                  type="button"
                  variant="white"
                  color="black"
                >
                  <DoubleArrowRightIcon width={18} height={18} />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      {openFilter && (
        <AsideModal
          handleClose={() => setOpenFilter(false)}
          title="Filters"
          footerComponent={null}
        >
          <GlobalFilter2
            filters={filters}
            setFilters={setFilters}
            setOpenFilter={setOpenFilter}
            initialFilterState={initialFilterState}
            isSearching={isLoading || isFetching}
            commented
            by
            me
          />
        </AsideModal>
      )}
    </>
  );
};

export default TableManager;
