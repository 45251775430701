import React, { useState } from 'react';
import TableManager from '../../components/TableManager';
import useIcons from '../../assets/icons/useIcons';
import Button from '../../components/Button';
import AsideModal from '../../components/AsideModal';
import AddConsumer from './AddConsumer';
import { getUsers, userLogin } from '../../api';
import useUserActivation from '../../hooks/useUserActivation';
import useUserDeactivation from '../../hooks/useUserDeactivation';
import Dropdown from '../../components/Dropdown';
import { useNavigate } from 'react-router-dom';
import { DateFilterTypes, formatDate } from '../../helpers/functions';

// const filterInitialValues = {
//   filter: [
//     {
//       account_number: '',
//       a_filter_type: '',
//       placeholder: 'Enter account',
//       type: 'text',
//       shouldFilterOptionDisabled: false,
//       label: 'Account',
//       filterName: 'account_number',
//       position: 1,
//     },
//     {
//       first_name: '',
//       a_filter_type: '',
//       placeholder: 'Enter first name',
//       type: 'text',
//       shouldFilterOptionDisabled: false,
//       label: 'First Name',
//       filterName: 'first_name',
//       position: 1,
//     },
//     {
//       last_name: '',
//       a_filter_type: '',
//       placeholder: 'Enter last name',
//       type: 'text',
//       shouldFilterOptionDisabled: false,
//       label: 'Last Name',
//       filterName: 'last_name',
//       position: 2,
//     },

//     {
//       phone: '',
//       a_filter_type: '',
//       placeholder: 'Enter phone number',
//       type: 'text',
//       shouldFilterOptionDisabled: false,
//       label: 'Phone',
//       filterName: 'phone',
//       position: 3,
//     },

//     {
//       user_email: '',
//       a_filter_type: '',
//       placeholder: 'Enter email',
//       type: 'text',
//       shouldFilterOptionDisabled: false,
//       label: 'Email',
//       filterName: ' user_email',
//       position: 3,
//     },
//   ],
// };

const filterInitialValues = {
  filter: [
    {
      key: 'account_number',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter account number',
      inputType: 'number',
      inputLabel: 'Account number',
    },
    {
      key: 'email',
      value: '',
      isChecked: false,
      inputPlaceholder: 'Enter Email',
      inputType: 'email',
      inputLabel: 'Email',
    },
  ],
};

const initialEditInstance = { isEdit: false, editData: {} };
export default function Consumer() {
  const { SuccessOutlineIcon, WarningOutlineIcon, CrossIcon } = useIcons();

  const [addMember, setAddMember] = useState(false);
  const { mutate: activateUser } = useUserActivation();
  const { mutate: deactivateUser } = useUserDeactivation();
  const [editUserInstance, setEditUserInstance] = useState(initialEditInstance);
  const { MoreVIcon } = useIcons();
  const navigate = useNavigate();
  const handleChangeStaus = (data) => {
    if (data?.is_active) {
      deactivateUser({ uuid: data?.uuid });
    } else {
      activateUser({ uuid: data?.uuid });
    }
  };

  const handleDropListClick = async ({ value }, data) => {
    switch (value) {
      case 'edit':
        // setEditUserInstance({ isEdit: true, editData: data });
        // setAddMember(true);
        break;
      case 'change-status':
        // handleChangeStaus(data);
        break;
      case 'view':
        // navigate(`/user-info/${data?.uuid}/profile`);
        break;
      case 'go_to_dashboard':
        // const res = await userLogin({ uuid: data?.uuid });
        // window.open(
        //   `${process.env.REACT_APP_PORTAL_URL}login-via-admin/${res?.data?.data}`,
        // );
        break;

      default:
        break;
    }
  };

  const handleUserDetailsModal = (data) => {
    // navigate(`/user-info/${data?.uuid}/dashboard`);
    navigate(`/user-info/${data?.uuid}/profile`);
  };

  const columns = [
    {
      accessorKey: 'accountNumber',
      header: () => 'Account#',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        const element = props.row.original;
        return (
          <span
            className="c--pointer text--danger font--600 d--flex gap--sm d--flex align-items--center justify-content--center"
            // onClick={() => handleUserDetailsModal(props?.row?.original)}
          >
            {element?.status === 'ACTIVATE' ? (
              <div
                data-tooltip={'Active'}
                tooltip-position="top"
                className="bg--success radius--full p-l--sm  p-r--sm w-min--20 h-min--20 w-max--20 h-max--20  text--white d--flex justify-content--center align-items--center"
              >
                A
              </div>
            ) : (
              <div
                data-tooltip={'Deactive'}
                tooltip-position="top"
                className="bg--secondary radius--full p-l--sm p-r--sm w-min--20 h-min--20 w-max--20 h-max--20 text--white d--flex justify-content--center align-items--center"
              >
                D
              </div>
            )}
            {props.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'full_name',
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      header: () => 'Full Name',
      meta: {
        textAlign: 'left',
      },
    },
    // {
    //   accessorKey: 'first_name',
    //   header: () => 'First Name',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },
    // {
    //   accessorKey: 'last_name',
    //   header: () => 'Last Name',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },
    {
      accessorKey: 'email',
      header: () => 'Email',
      meta: {
        textAlign: 'left',
      },
    },
    {
      accessorKey: 'phone',
      header: () => 'Phone',
      meta: {
        textAlign: 'left',
      },
    },
    // {
    //   accessorKey: 'tenant_company',
    //   header: () => 'Agent Company',
    //   meta: {
    //     textAlign: 'left',
    //     width: '12%',
    //   },
    // },
    // {
    //   accessorKey: 'role',
    //   header: () => 'User Type',
    //   meta: {
    //     textAlign: 'left',
    //   },
    //   cell: ({ getValue }) => {
    //     if (getValue() === 'AGENCY_USER') {
    //       return 'AGENCY';
    //     } else if (getValue() === 'MEMBER') {
    //       return 'CONSUMER';
    //     } else {
    //       return getValue();
    //     }
    //   },
    // },
    // {
    //   accessorKey: 'NPN',
    //   header: () => 'NPN',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },

    // {
    //   accessorKey: 'verifySSN',
    //   header: () => 'SSN Verified',
    //   meta: {
    //     textAlign: 'left',
    //   },
    //   cell: (props) => {
    //     const element = props.row.original;
    //     return (
    //       <span className="c--pointer text--danger font--600 ">
    //         {element?.verifySSN ? (
    //           <div
    //             data-tooltip={'Verified'}
    //             tooltip-position="top"
    //             className="bg--success radius--full w-min--24 w-max--24 h-min--24 h-max--24  text--white d--flex justify-content--center align-items--center"
    //           >
    //             <SuccessOutlineIcon width={25} height={25} />
    //           </div>
    //         ) : (
    //           <div
    //             data-tooltip={'Not Verified'}
    //             tooltip-position="top"
    //             className="bg--danger radius--full p-l--sm p-r--sm tw-w-10 tw-h-10 text--white d--flex justify-content--center align-items--center"
    //           >
    //             <CrossIcon height={25} width={25}/>
    //           </div>
    //         )}
    //       </span>
    //     );
    //   },
    // },

    {
      accessorKey: 'verifyStatus',
      header: () => 'Verified',
      meta: {
        textAlign: 'center',
      },
      cell: (props) => {
        // const element = props.row.original;
        return (
          <span className="c--pointer text--danger font--600 d--flex justify-content--center">
            {props.getValue() ? (
              <div
                data-tooltip={'Verified'}
                tooltip-position="top"
                className="bg--success radius--full w-min--24 w-max--24 h-min--24 h-max--24   text--white d--flex justify-content--center align-items--center"
              >
                <SuccessOutlineIcon width={25} height={25} />
              </div>
            ) : (
              <div
                data-tooltip={'Not Verified'}
                tooltip-position="top"
                className="bg--danger radius--full p-l--sm p-r--sm tw-w-10 tw-h-10 text--white d--flex justify-content--center align-items--center"
              >
                <CrossIcon height={25} width={25} />
              </div>
            )}
          </span>
        );
      },
    },

    // {
    //   accessorKey: 'agency_name',
    //   header: () => 'Agency Name',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },
    // {
    //   accessorKey: 'agency_email',
    //   header: () => 'Agency Email',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },
    // {
    //   accessorKey: 'agency_phone',
    //   header: () => 'Agency Phone',
    //   meta: {
    //     textAlign: 'left',
    //   },
    // },

    // {
    //   accessorKey: 'VerifyAgencyNPN',
    //   header: () => 'Agency NPN',
    //   meta: {
    //     textAlign: 'left',
    //   },
    //   cell: (props) => {
    //     // const element = props.row.original;
    //     return (
    //       <span className="c--pointer text--danger font--600 tw-flex  ">
    //         {props.getValue() ? (
    //           <div
    //             data-tooltip={'Verified'}
    //             tooltip-position="top"
    //             className="bg--success radius--full w-min--24 w-max--24 h-min--24 h-max--24   text--white d--flex justify-content--center align-items--center"
    //           >
    //             <SuccessOutlineIcon width={25} height={25} />
    //           </div>
    //         ) : (
    //           <div
    //             data-tooltip={'Not Verified'}
    //             tooltip-position="top"
    //             className="bg--danger radius--full p-l--sm p-r--sm tw-w-10 tw-h-10 text--white d--flex justify-content--center align-items--center"
    //           >
    //             <CrossIcon height={25} width={25}/>
    //           </div>
    //         )}
    //       </span>
    //     );
    //   },
    // },

    // {
    //   accessorKey: 'VerifyAgencyEIN',
    //   header: () => 'Agency EIN',
    //   meta: {
    //     textAlign: 'left',
    //   },
    //   cell: (props) => {
    //     // const element = props.row.original;
    //     return (
    //       <span className="c--pointer text--danger font--600 ">
    //         {props.getValue() ? (
    //           <div
    //             data-tooltip={'Verified'}
    //             tooltip-position="top"
    //             className="bg--success radius--full w-min--24 w-max--24 h-min--24 h-max--24   text--white d--flex justify-content--center align-items--center"
    //           >
    //             <SuccessOutlineIcon width={25} height={25} />
    //           </div>
    //         ) : (
    //           <div
    //             data-tooltip={'Not Verified'}
    //             tooltip-position="top"
    //             className="bg--danger radius--full p-l--sm p-r--sm tw-w-10 tw-h-10 text--white d--flex justify-content--center align-items--center"
    //           >
    //             <CrossIcon height={25} width={25}/>
    //           </div>
    //         )}
    //       </span>
    //     );
    //   },
    // },

    {
      accessorKey: 'createdAt',
      header: () => 'Registration On',
      cell: (props) => formatDate(props.getValue()),
      meta: {
        textAlign: 'center',
      },
    },
    {
      accessorKey: 'action',
      header: () => 'Action',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <div className="gap--xs w--full d--flex align-items--center justify-content--center">
            <Dropdown
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => (
                  <span
                    className="w--full d--flex  justify-content--start p--sm"
                    onClick={() => handleDropListClick(item, element)}
                  >
                    {item?.title}
                  </span>
                ),
                data: [
                  { title: 'View', value: 'view' },
                  { title: 'Edit', value: 'edit' },
                  {
                    title: `${
                      element?.status == 'ACTIVATE' ? 'Deactivate' : 'Activate'
                    }`,
                    value: 'change-status',
                  },
                  { title: 'Go to dashboard', value: 'go_to_dashboard' },
                ],
              }}
              showcaret={false}
            >
              <div className="w-min--32 h-min--32 d--flex align-items--center justify-content--center radius--sm bg--black-100 text--black  c--pointer">
                <MoreVIcon width={15} height={15} />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        colType: 'action',
        textAlign: 'center',
        width: '5%',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      access: true,
      component: (
        <Button
          type="button"
          btnClasses="btn w-min--150"
          variant="primary"
          color="white"
          onClick={() => setAddMember(true)}
        >
          Add User
        </Button>
      ),
    },
  ];
  const handleClose = () => {
    setEditUserInstance({ isEdit: false, editData: {} });
    setAddMember(false);
  };

  return (
    <div className="w--full">
      <TableManager
        {...{
          fetcherKey: 'getUsers',
          fetcherFn: getUsers,
          columns,
          // tableActions,
          tableActions: [],
          name: 'Consumer',
          role: 'MEMBER',
          shouldFilter: true,
          initialValues: filterInitialValues,
          initialFilterState: filterInitialValues,
        }}
      />
      {addMember && (
        <AsideModal
          handleClose={handleClose}
          title="User"
          footerComponent={null}
        >
          <AddConsumer
            editUserInstance={editUserInstance}
            handleClose={handleClose}
          />
        </AsideModal>
      )}
    </div>
  );
}
