import React, { useEffect, useState } from 'react';
import Button from '../Button';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import FormInput from '../FormInput';

const GlobalFilter2 = ({
  filters,
  setFilters,
  initialFilterState,
  setOpenFilter,
  isSearching = false,
}) => {
  const [state, setState] = useState(initialFilterState?.filter ?? []);
  useEffect(() => {
    if (Array.isArray(filters) && filters?.length > 0) {
      setState(filters);
    }
  }, [filters]);
  return (
    <form
      className="w--full h--full"
      onSubmit={(e) => {
        e.preventDefault();
        setOpenFilter(false);
        // setFilters(state); commented by me
      }}
    >
      <div className="overflow--auto dialogScroll d--flex flex--column gap--md p--md">
        {state.map((filter, index) => {
          if (filter?.inputType === 'date') {
            return (
              <div
                className="d--flex flex--column gap--md bg--primary-100 border-full--primary-100 p--md radius--sm w--full"
                key={filter?.key ?? index}
              >
                <RenderDateFilterTypes filter={filter} setState={setState} />
                <RenderDateInputs filter={filter} setState={setState} />
              </div>
            );
          }
          return (
            <div
              className="d--flex gap--md h--full align-items--center"
              key={filter?.key ?? index}
            >
              <div className="w--full w-max--150">
                <RenderLabel filter={filter} setState={setState} />
              </div>
              <div className="w--full">
                <RenderInputField filter={filter} setState={setState} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="d--flex justify-content--between gap--md p-l--md p-r--md p-t--md border-top--primary-100">
        <Button
          type="button"
          variant="primary-100"
          color="black"
          btnClasses="btn"
          borderStyle="primary"
          isOutline
          onClick={() => {
            if (isAnyFilterChecked({ state })) {
              setOpenFilter(false);
              setFilters([]);
            } else {
              setOpenFilter(false);
            }
          }}
        >
          {isAnyFilterChecked({ state }) ? 'Clear' : 'Close'}
        </Button>
        <Button
          type="submit"
          variant="primary"
          color="black"
          btnClasses="btn"
          disabled={isSearching || !isAnyFilterChecked({ state })}
        >
          Search
        </Button>
      </div>
    </form>
  );
};

export default GlobalFilter2;

const RenderLabel = ({ filter, setState }) => {
  const { key = '', isChecked = false, inputLabel = '' } = filter;
  return (
    <div className="d--flex gap--sm ">
      <input
        id={key}
        type="checkbox"
        className="form--control no--focus"
        checked={isChecked}
        onChange={(e) =>
          handleUpdateState({
            filter,
            setState,
            key,
            valueToUpdate: { isChecked: e.target.checked, value: '' },
          })
        }
      />
      <label
        className={`label--control font--sm font--500  d--flex c--pointer`}
        htmlFor={key}
      >
        {inputLabel}
      </label>
    </div>
  );
};

const RenderInputField = ({ filter, setState }) => {
  const {
    key = '',
    isChecked = false,
    inputType = '',
    inputPlaceholder = '',
    value = '',
  } = filter;
  if (inputType === 'text' || inputType === 'email' || inputType === 'number') {
    return (
      <div className="d--flex gap-sm">
        <FormInput
          required={isChecked}
          type={inputType}
          value={value}
          className=""
          placeholder={inputPlaceholder}
          disabled={!isChecked}
          onChange={(e) =>
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value: e.target.value },
            })
          }
        />
      </div>
    );
  } else if (inputType === 'select') {
    const { inputOptions = [] } = filter;
    return (
      <div className="d--flex gap-sm">
        <select
          required={isChecked}
          value={value}
          className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
          disabled={!isChecked}
          placeholder={inputPlaceholder}
          onChange={(e) =>
            handleUpdateState({
              setState,
              key,
              filter,
              valueToUpdate: { value: e.target.value },
            })
          }
        >
          <option value={''}>{inputPlaceholder}</option>
          {inputOptions?.map((item) => {
            return (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  } else if (inputType === 'phone-number') {
    return (
      <PhoneInput
        numberInputProps={{
          required: isChecked,
        }}
        // defaultCountry={'US'}
        international={true}
        addInternationalOption={false}
        withCountryCallingCode={true}
        placeholder={inputPlaceholder}
        internationalIcon={() => null}
        flagComponent={() => null}
        countrySelectComponent={() => null}
        value={value}
        limitMaxLength={true}
        disabled={!isChecked}
        onChange={(phone) =>
          handleUpdateState({
            setState,
            key,
            filter,
            valueToUpdate: { value: phone },
          })
        }
        className={`form--control w--full h-min--36  radius--sm p-l--md p-r--md border-full--black-200`}
      />
    );
  }
  return <></>;
};

const RenderDateFilterTypes = ({ filter, setState }) => {
  const {
    dateFilterTypes = [],
    dateType = '',
    inputPlaceholder = 'Select filter type',
  } = filter;
  return (
    <select
      className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200"
      value={dateType}
      onChange={(e) =>
        handleUpdateState({
          setState,
          filter,
          findByKey: 'inputType',
          key: 'date',
          valueToUpdate: {
            dateType: e.target.value,
            value: generateDateObjectFromTo({ dateType: e.target.value }),
          },
        })
      }
    >
      <option value="">{inputPlaceholder}</option>
      {dateFilterTypes.map((item, index) => (
        <option value={item?.value} key={index}>
          {item?.label}
        </option>
      ))}
    </select>
  );
};

const RenderDateInputs = ({ filter, setState }) => {
  const { dateType = '', value } = filter;
  if (dateType === 'Select Date') {
    return (
      <input
        name="selectDate"
        id="selectDate"
        type="date"
        value={value?.from ?? ''}
        placeholder="Select or type a date..."
        className="form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200 w--full"
        max={moment().format('YYYY-MM-DD')}
        onChange={(e) =>
          handleUpdateState({
            filter,
            setState,
            key: 'date',
            findByKey: 'inputType',
            valueToUpdate: {
              value: {
                from: e.target.value,
                to: e.target.value,
              },
            },
          })
        }
      />
    );
  }
  return (
    <span className="d--flex gap--md">
      <input
        className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200`}
        type="date"
        name="startDate"
        id="startDate"
        value={value?.from ?? ''}
        disabled={dateType !== 'Custom'}
        max={moment().format('YYYY-MM-DD')}
        onChange={(e) =>
          handleUpdateState({
            filter,
            setState,
            key: 'date',
            findByKey: 'inputType',
            valueToUpdate: {
              value: {
                from: e.target.value,
                to: value?.to,
              },
            },
          })
        }
      />
      <input
        className={`form--control w--full h-min--36 radius--sm p-l--md p-r--md border-full--black-200`}
        type="date"
        name="endDate"
        id="endDate"
        value={value?.to ?? ''}
        disabled={dateType !== 'Custom'}
        max={moment().format('YYYY-MM-DD')}
        onChange={(e) =>
          handleUpdateState({
            filter,
            setState,
            key: 'date',
            findByKey: 'inputType',
            valueToUpdate: {
              value: {
                from: value?.from,
                to: e.target.value,
              },
            },
          })
        }
      />
    </span>
  );
};

const handleUpdateState = ({
  setState,
  key,
  findByKey = 'key',
  filter,
  valueToUpdate,
}) => {
  setState((prev) => {
    const newState = [...prev];
    const index = newState.findIndex((value) => value?.[findByKey] === key);
    if (index > -1) {
      newState.splice(index, 1, { ...filter, ...valueToUpdate });
    }
    return newState;
  });
};

// utils func
const generateDateObjectFromTo = ({ dateType }) => {
  let object = { from: '', to: '' };
  switch (dateType) {
    case 'Today':
      object = {
        from: moment(),
        to: moment(),
      };
      break;
    case 'Yesterday':
      object = {
        from: moment().subtract(1, 'days'),
        to: moment().subtract(1, 'days'),
      };
      break;

    case 'Last 7 Days':
      object = {
        from: moment().subtract(6, 'days'),
        to: moment(),
      };
      break;
    case 'Last 30 Days':
      object = {
        from: moment().subtract(29, 'days'),
        to: moment(),
      };
      break;
    case 'This Month':
      object = {
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      };
      break;
    case 'Last Month':
      object = {
        from: moment().subtract(1, 'month').startOf('month'),
        to: moment().subtract(1, 'month').endOf('month'),
      };
      break;
    default:
      object = { from: '', to: '' };
      break;
  }
  return {
    from: object.from ? object.from.format('YYYY-MM-DD') : '',
    to: object.to ? object.to.format('YYYY-MM-DD') : '',
  };
};

const isAnyFilterChecked = ({ state }) => {
  return state.find((item) => {
    if (item?.inputType !== 'date' && item?.isChecked === true) {
      return true;
    } else if (
      item?.inputType === 'date' &&
      item?.value?.from &&
      item?.value?.to
    ) {
      return true;
    } else {
      return false;
    }
  });
};
